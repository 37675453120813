<template>
  <vx-card
    :title="
      this.routeAssignmentID
        ? 'Edit Route Assignment'
        : 'Create Route Assignment'
    "
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="code" :readonly="true"></vs-input>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :disabled="readonly"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLabelTerritory"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validFrom"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validFrom')">{{
          errors.first("validFrom")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validTo"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validTo')">{{
          errors.first("validTo")
        }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <table width="100%" class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
            <tr>
              <th width="30%">Salesman</th>
              <th width="30%">Route Plan</th>
              <th width="30%">Product Team</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(child, index) in personalLines"
              :key="index"
              style="padding-bottom;: 5px"
            >
              <td class="td vs-table--td" style="padding: 5px">
                <v-select
                  :filterable="false"
                  :options="personalLines[index].option_personal"
                  v-model="personalLines[index].selected_personal"
                  @input="
                    (val) => {
                      onChangePersonal(val, index);
                    }
                  "
                  @search="
                    (search, loading) => {
                      onSearchPersonal(search, loading, index);
                    }
                  "
                />
              </td>

              <td class="td vs-table--td" style="padding-right: 10px">
                <v-select
                  multiple
                  :filterable="false"
                  :options="personalLines[index].option_route_plan"
                  v-model="personalLines[index].selected_route_plan"
                  @search="
                    (search, loading) => {
                      onSearchRoutePlan(search, loading, index);
                    }
                  "
                />
              </td>

              <td class="td vs-table--td" style="padding-right: 10px">
                <v-select
                  multiple
                  :filterable="false"
                  :options="personalLines[index].option_product_team"
                  v-model="personalLines[index].selected_product_team"
                  @search="
                    (search, loading) => {
                      onSearchProductTeam(search, loading, index);
                    }
                  "
                />
              </td>

              <td
                width="75px"
                class="td vs-table--td"
                style="text-align: center"
              >
                <div class="vx-input-group flex">
                  <vs-button
                    v-if="personalLines.length > 1"
                    @click.stop="removeRow(index)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Row"
                  />

                  <vs-button
                    v-if="index == personalLines.length - 1"
                    @click.stop="addRow()"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Row"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vx-input-group flex">
      <div
        style="
          position: absolute;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        "
      >
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleBack"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import draggable from "vuedraggable";
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/route-assignment",
        optionTerritory: [],
        selectedTerritory: null,
        routeAssignmentID: null,
        code: null,
        validFrom: null,
        validTo: null,
        personalLines: [
          {
            option_personal: [],
            selected_personal: null,
            option_route_plan: [],
            // selected_route_plan: null,
            selected_route_plan: [],
            option_product_team: [],
            selected_product_team: [],
          },
        ],
        optionRoutePlan: [],
        optionPersonal: [],
        readonly: false,
      };
    },
    handleBack() {
      this.$router.push({
        name: "route-assignment",
      });
    },
    handleSubmit() {
      let isFail = false;
      let personalLines = [];
      this.personalLines.forEach((el) => {
        let routePlanIDs = [];
        el.selected_route_plan.forEach((e) => {
          routePlanIDs.push(e.id);
        });

        // if (el.selected_route_plan.id) {
        //   routePlanIDs.push(el.selected_route_plan.id);
        // } else {
        //   this.$vs.notify({
        //     color: "danger",
        //     title: "Error",
        //     text: "Route plan is required",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-x-circle",
        //   });
        // }

        if (routePlanIDs.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Route plan is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          isFail = true;
          return false;
        }

        let productTeamIDs = [];
        el.selected_product_team.forEach((e) => {
          productTeamIDs.push(e.id);
        });

        if (el.selected_personal.personal_id) {
          personalLines.push({
            personal_id: el.selected_personal.personal_id,
            route_plan_ids: routePlanIDs,
            product_team_ids: productTeamIDs,
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Salesman is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });

          isFail = true;
          return false;
        }
      });

      if (isFail) {
        return false;
      }

      if (!this.validFrom || !this.validTo) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }

      let body = {
        territory_id: this.selectedTerritory.id,
        valid_from: this.validFrom
          ? moment(this.validFrom).format("YYYY-MM-DD")
          : null,
        valid_to: this.validTo
          ? moment(this.validTo).format("YYYY-MM-DD")
          : null,
        personal_lines: personalLines,
        status: "Draft",
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          let res = null;
          if (this.routeAssignmentID) {
            res = this.$http.put(
              this.baseUrl + "/" + this.routeAssignmentID + "/update",
              body
            );
          } else {
            res = this.$http.post(this.baseUrl + "/create", body);
          }

          res
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },

    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    onChangePersonal(val, index) {
      let cek = this.personalLines.filter((el, i) => {
        if (el.selected_personal) {
          if (
            el.selected_personal.personal_id == val.personal_id &&
            i != index
          ) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.personalLines[index].selected_personal = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Sales already add",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      this.personalLines[index].selected_personal = val;
    },
    onSearchPersonal(search, loading, index) {
      if (search.length && this.selectedTerritory) {
        loading(true);
        this.searcPersonal(loading, search, this, index);
      }
    },
    searcPersonal: _.debounce((loading, search, t, index) => {
      t.$http
        .get(t.baseUrl + "/sales", {
          params: {
            length: 10,
            search: search.trim(),
            territory_id: t.selectedTerritory.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                // return (x.label = x.work_id_number + " " + x.name);
                return (x.label =
                  x.work_id_number + " (" + x.code_external + ") " + x.name);
              });
              t.personalLines[index].option_personal = resp.data.records;
            } else {
              t.personalLines[index].option_personal = [];
            }
            loading(false);
          } else {
            t.personalLines[index].option_personal = [];
            loading(false);
          }
        });
    }, 350),
    onSearchRoutePlan(search, loading, index) {
      if (search.length && this.selectedTerritory) {
        loading(true);
        this.searcRoutePlan(loading, search, this, index);
      }
    },
    searcRoutePlan: _.debounce((loading, search, t, index) => {
      t.$http
        .get(t.baseUrl + "/route-plan", {
          params: {
            length: 10,
            search: search.trim(),
            territory_id: t.selectedTerritory.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label = x.code + " " + x.schedule_type);
              });
              t.personalLines[index].option_route_plan = resp.data.records;
            } else {
              t.personalLines[index].option_route_plan = [];
            }
            loading(false);
          } else {
            t.personalLines[index].option_route_plan = [];
            loading(false);
          }
        });
    }, 350),

    onSearchProductTeam(search, loading, index) {
      if (search.length) {
        loading(true);
        this.searcProductTeam(loading, search, this, index);
      }
    },
    searcProductTeam: _.debounce((loading, search, t, index) => {
      t.$http
        .get(t.baseUrl + "/product-team", {
          params: {
            length: 10,
            search: search.trim(),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label = x.code + " " + x.name);
              });
              t.personalLines[index].option_product_team = resp.data.records;
            } else {
              t.personalLines[index].option_product_team = [];
            }
            loading(false);
          } else {
            t.personalLines[index].option_product_team = [];
            loading(false);
          }
        });
    }, 350),

    getRouteAssignment() {
      if (this.routeAssignmentID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.routeAssignmentID + "/edit")
          .then((resp) => {
            if (resp.code == 200) {
              this.code = resp.data.code;
              this.selectedTerritory = resp.data.territory;
              this.validFrom = new Date(resp.data.valid_from);
              this.validTo = new Date(resp.data.valid_to);

              let personalLines = [];

              resp.data.personal_lines.forEach((el) => {
                personalLines.push({
                  option_personal: [],
                  selected_personal: el.personal.id != 0 ? el.personal : null,
                  option_route_plan: [],
                  // selected_route_plan:
                  //   el.route_plans != 0 ? el.route_plans : null,
                  selected_route_plan:
                    el.route_plans != 0 ? el.route_plans : [],
                  option_product_team: [],
                  selected_product_team: el.product_teams
                    ? el.product_teams
                    : [],
                });
              });

              this.personalLines = personalLines;
            }
            this.$vs.loading.close();
          });
      } else {
        this.optionPersonal = [];
      }
    },

    customLabelTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    addRow() {
      this.personalLines.push({
        option_personal: [],
        selected_personal: null,
        option_route_plan: [],
        // selected_route_plan: null,
        selected_route_plan: [],
        option_product_team: [],
        selected_product_team: [],
      });
    },
    removeRow(index) {
      this.personalLines.splice(index, 1);
    },

    setSelectedTerritory(territorryID) {
      let cek = this.optionTerritory.filter((el) => {
        if (el.id == territorryID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedTerritory = cek[0];
      } else {
        this.selectedTerritory = null;
      }
    },
  },
  beforeMount() {
    this.getTerritory();
  },
  mounted() {
    this.routeAssignmentID = this.$route.params.id;
    this.getRouteAssignment();

    if (this.routeAssignmentID) {
      this.readonly = true;
    }
  },
  watch: {
    selectedTerritory() {},

    personalLines: {
      handler: function (val) {
        // console.log(val);
      },
      deep: true,
    },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}
.multiselect__content-wrapper {
  z-index: 10;
}
</style>
